import {
  ICartControllerApi,
  CartStoreProps,
  SettingsOverrides,
  VeloInputs,
  ExperimentProps,
} from '../../types/app.types';
import {CartService} from '../services/CartService';
import {ProductsService} from '../services/ProductsService';
import {BIService} from '../services/BIService';
import {FedopsInteractions} from '../../common/constants';
import {StyleSettingsService} from '../services/StyleSettingsService';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {SPECS} from '../specs';
import {MinimumOrderAmountService} from '../services/MinimumOrderAmountService';
import {calcTopThreeViolations} from '../utils/violations.utils';
import {LineItemModel} from '../models/LineItem.model';
import {ControllerParams} from '@wix/yoshi-flow-editor';

export class CartStore {
  private readonly biService: BIService;
  private readonly cartService: CartService;
  private readonly productsService: ProductsService;
  private readonly controllerApi: ICartControllerApi;
  private readonly flowAPI: ControllerParams['flowAPI'];
  private readonly styleSettingsService: StyleSettingsService;
  private readonly minimumOrderAmountService: MinimumOrderAmountService;
  private screenReaderMessage: string;
  private settingsOverrides: SettingsOverrides;

  constructor(
    flowAPI: ControllerParams['flowAPI'],
    controllerApi: ICartControllerApi,
    private readonly siteStore: SiteStore,
    {
      biService,
      cartService,
      productsService,
      styleSettingsService,
      minimumOrderAmountService,
    }: {
      biService: BIService;
      cartService: CartService;
      productsService: ProductsService;
      styleSettingsService: StyleSettingsService;
      minimumOrderAmountService: MinimumOrderAmountService;
    },
    veloInputs: VeloInputs
  ) {
    this.setOverrides(veloInputs);
    this.controllerApi = controllerApi;
    this.flowAPI = flowAPI;
    this.biService = biService;
    this.cartService = cartService;
    this.productsService = productsService;
    this.styleSettingsService = styleSettingsService;
    this.minimumOrderAmountService = minimumOrderAmountService;
  }

  private readonly updateItemQuantity = async (cartItemId: number, quantity: number) => {
    this.cartService.showLoaderOnItem(cartItemId);
    await this.controllerApi.updateComponent();
    return this.controllerApi.reportFedops(FedopsInteractions.UpdateQuantity, () =>
      this.cartService.updateItemQuantity(cartItemId, quantity)
    );
  };

  private readonly removeItemFromCart = async (lineItem: LineItemModel) => {
    this.cartService.showLoaderOnItem(lineItem.id);
    await this.controllerApi.updateComponent();
    this.screenReaderMessage = this.controllerApi.t('cart.sr_item_was_removed', {item_name: lineItem.productName});
    return this.controllerApi.reportFedops(FedopsInteractions.RemoveItem, () =>
      this.cartService.removeItemFromCart(lineItem)
    );
  };

  private readonly applyCoupon = async (code: string) => {
    await this.controllerApi.reportFedops(FedopsInteractions.ApplyCoupon, () => this.cartService.applyCoupon(code));
  };

  private readonly removeCoupon = async () => {
    return this.controllerApi.reportFedops(FedopsInteractions.ApplyCoupon, () => this.cartService.removeCoupon());
  };

  private readonly sendToggleCouponBi = () => {
    return this.biService.clickOnApplyPromotionalCodeSf(this.cartService.cartModel);
  };

  private readonly sendAddNoteBi = () => {
    return this.biService.clickOnAddNoteToSellerSf(this.cartService.cartModel);
  };

  private readonly sendMinimumOrderMessageShownBi = (buttonEnabled: boolean) => {
    return this.biService.minimumOrderMessageIsShownInCart(
      this.cartService.cartModel,
      this.cartService.estimatedTotals,
      buttonEnabled
    );
  };

  private readonly sendEstimateTotalsErrorClickOnTryAgainBi = () => {
    return this.biService.cartEstimateTotalsErrorClickOnTryAgain(
      this.cartService.cartModel,
      this.cartService.cartLayout
    );
  };

  private readonly updateBuyerNote = async (...args: Parameters<CartService['updateBuyerNote']>) => {
    return this.cartService.updateBuyerNote(...args);
  };

  private readonly fetchEstimateTotals = async () => {
    return this.cartService.fetchEstimateTotals();
  };

  private readonly getProductsManifest = () => {
    return this.productsService.manifest(this.cartService.cartModel);
  };

  private get isCartValid(): boolean {
    return this.cartService.areAllItemsInStock;
  }

  private get isPartiallyPaid(): boolean {
    return this.cartService.estimatedTotals?.payLater.total.convertedAmount > 0;
  }

  private get shouldDisplayViolations(): boolean {
    return this.cartService.estimatedTotals?.violations?.length > 0;
  }

  private readonly lineItemsLoaded = () => {
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.lineItemsLoaded);
  };

  private readonly pageLoaded = () => {
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.padeLoaded);
  };

  public setOverrides(veloInputs: VeloInputs) {
    this.settingsOverrides = {
      ...this.settingsOverrides,
      ...veloInputs,
    };
  }

  public async toProps(): Promise<CartStoreProps> {
    const experimentProps: ExperimentProps = {
      shouldPresentTooltipWithoutNumber: this.siteStore.experiments.enabled(SPECS.CartTooltipWithoutNumber),
      AddSlotToCartPage: this.siteStore.experiments.enabled(SPECS.AddSlotToCartPage),
      sideCartElementsVisibilityInCss: this.siteStore.experiments.enabled(SPECS.SideCartElementsVisibilityInCss),
      cartFromSDKWhenShowShippingOrShowTax: this.siteStore.experiments.enabled(
        SPECS.CartFromSDKWhenShowShippingOrShowTax
      ),
      useCurrentCartFromSdk: this.siteStore.experiments.enabled(SPECS.UseCurrentCartFromSdk),
      mapCartSummaryColors: this.siteStore.experiments.enabled(SPECS.MapCartSummaryColors),
      checkoutButtonLinkedToSecureCheckout: this.siteStore.experiments.enabled(
        SPECS.CheckoutButtonLinkedToSecureCheckout
      ),
      isGiftCardMixedCartEnabled: this.siteStore.experiments.enabled(SPECS.GiftCardAddToCartSettings),
      hideDepositWhenZero: this.siteStore.experiments.enabled(SPECS.HideDepositWhenZero),
      useProductThumbnailWithWowImage: this.siteStore.experiments.enabled(SPECS.UseProductThumbnailWithWowImage),
      useSkeletonLineFromWUT: this.siteStore.experiments.enabled(SPECS.UseSkeletonLineFromWUT),
      FixCustomLineItemImage: this.siteStore.experiments.enabled(SPECS.FixCustomLineItemImage),
    };

    if (this.cartService.isEmpty) {
      return {
        shouldRenderEmptyState: true,
        lineItemsLoaded: this.lineItemsLoaded,
        pageLoaded: this.pageLoaded,
        ...experimentProps,
      } as unknown as CartStoreProps;
    }

    return {
      loadingItems: this.cartService.loadingItems,
      cartModel: this.cartService.cartModel,
      estimatedTotals: this.cartService.estimatedTotals,
      itemsCount: this.cartService.cartModel?.lineItems?.reduce((count, lineItem) => count + lineItem.quantity, 0),
      isNonShippableCart: this.cartService.isNonShippableCart,
      manifest: await this.getProductsManifest(),
      applyCoupon: this.applyCoupon,
      removeCoupon: this.removeCoupon,
      couponError: this.cartService.couponError,
      isFreeCart: this.cartService.isZeroCart,
      sendToggleCouponBi: this.sendToggleCouponBi,
      removeItemFromCart: this.removeItemFromCart,
      sendAddNoteBi: this.sendAddNoteBi,
      sendMinimumOrderMessageShownBi: this.sendMinimumOrderMessageShownBi,
      sendEstimateTotalsErrorClickOnTryAgainBi: this.sendEstimateTotalsErrorClickOnTryAgainBi,
      updateBuyerNote: this.updateBuyerNote,
      updateItemQuantity: this.updateItemQuantity,
      screenReaderMessage: this.screenReaderMessage,
      isCartValid: this.isCartValid,
      shouldShowCoupon: this.styleSettingsService.shouldShowCoupon,
      shouldShowBuyerNote: this.styleSettingsService.shouldShowBuyerNote,
      shouldShowMinimumOrderAmount: this.minimumOrderAmountService.shouldDisplayNotification,
      shouldShowTopCheckoutButtonInMobile: this.styleSettingsService.shouldShowTopCheckoutButtonInMobile,
      shouldDisplayViolations: this.shouldDisplayViolations,
      shouldShowSecureCheckout: this.settingsOverrides.shouldShowSecureCheckout,
      isPartiallyPaid: this.isPartiallyPaid,
      hasErrorViolations: this.cartService.hasErrorViolations,
      topThreeViolations: calcTopThreeViolations(this.cartService.estimatedTotals?.violations),
      ...experimentProps,
      shouldShowError: this.cartService.hasError,
      errorOnEstimateTotals: this.cartService.errorOnEstimateTotals,
      clearError: this.cartService.clearError,
      lineItemsLoaded: this.lineItemsLoaded,
      pageLoaded: this.pageLoaded,
      isSummaryUpdating: this.cartService.isSummaryUpdating,
      isSummaryLoading: this.cartService.isSummaryLoading,
      shouldEstimateTotals: this.cartService.shouldEstimateTotals(),
      fetchEstimateTotals: this.fetchEstimateTotals,
    };
  }
}
