import {BaseCartController} from '../domain/controllers/BaseCartController';
import {CartStore} from '../domain/stores/CartStore';
import {OrderStore} from '../domain/stores/OrderStore';
import {PaymentMethodStore} from '../domain/stores/PaymentMethodStore';
import {StyleParam} from '../common/constants';
import {
  ICart as ICartFromCartApi,
  DynamicPaymentMethodsShape,
  DynamicPaymentMethodsTheme,
} from '@wix/wixstores-client-storefront-sdk';
import {CashierExpressStore} from '../domain/stores/CashierExpressStore';
import {NavigationStore} from '../domain/stores/NavigationStore';
import {CartModel} from '../domain/models/Cart.model';
import {CouponError} from '../domain/services/CartService';
import {calcTopThreeViolations} from '../domain/utils/violations.utils';
import {LineItemModel} from '../domain/models/LineItem.model';
import {EstimatedTotalsModel} from '../domain/models/EstimatedTotals.model';

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export type IControllerProps = {
  cartStore: Awaited<ReturnType<CartStore['toProps']>>;
  cashierExpressStore: Awaited<ReturnType<CashierExpressStore['toProps']>>;
  generalError: boolean;
  isResponsive: boolean;
  navigationStore: Awaited<ReturnType<NavigationStore['toProps']>>;
  paymentMethodStore: Awaited<ReturnType<PaymentMethodStore['toProps']>>;
  orderStore: Awaited<ReturnType<OrderStore['toProps']>>;
  fitToContentHeight: boolean;
};

export type ICartControllerApi = {
  executeWithLoader: BaseCartController['executeWithLoader'];
  updateComponent: BaseCartController['updateComponent'];
  reportFedops: BaseCartController['reportFedops'];
  reportStartFedops: BaseCartController['reportStartFedops'];
  reportEndFedops: BaseCartController['reportEndFedops'];
  t: BaseCartController['t'];
};

export type IMedialDimensions = {
  width: number;
  height: number;
};
export type ICartProductsManifest = Record<string, {href: string}>;

export enum ButtonSkins {
  BUTTON_SKIN_1 = 'button-skin-1',
  BUTTON_SKIN_2 = 'button-skin-2',
  BUTTON_SKIN_3 = 'button-skin-3',
  BUTTON_SKIN_4 = 'button-skin-4',
  BUTTON_SKIN_5 = 'button-skin-5',
  BUTTON_SKIN_6 = 'button-skin-6',
}

export type ICartStyleSettings = {
  booleans: {
    [StyleParam.ShowContinueShopping]: boolean;
    [StyleParam.ShowCoupon]: boolean;
    [StyleParam.ShowBuyerNote]: boolean;
    [StyleParam.ShowExpressCheckout]: boolean;
    [StyleParam.ShowTax]: boolean;
    [StyleParam.ShowShipping]: boolean;
    [StyleParam.Responsive]: boolean;
    [StyleParam.ShowCheckoutButton]: boolean;
    [StyleParam.ShowGoToCartButton]: boolean;
    [StyleParam.CartItemQuantityCounterVisibility]: boolean;
    [StyleParam.CartItemImageVisibility]: boolean;
    [StyleParam.CartItemInfo]: boolean;
    [StyleParam.CartSummaryDisclaimerVisibility]: boolean;
    [StyleParam.CartSummarySecureBadgeVisibility]: boolean;
    [StyleParam.cartTitleVisibility]: boolean;
    [StyleParam.cartNumberOfCartItemsVisibility]: boolean;
  };
  fonts: {
    [StyleParam.CornerRadius]: {value: string; fontStyleParam: boolean};
    [StyleParam.SelectedSkin]: {value: ButtonSkins; fontStyleParam: boolean};
  };
  numbers: {
    [StyleParam.dynamicPaymentMethodsShape]: DynamicPaymentMethodsShape;
    [StyleParam.dynamicPaymentMethodsTheme]: DynamicPaymentMethodsTheme;
    [StyleParam.CheckoutButtonsPosition]: number;
  };
};

export type IShippingInfo = ICartFromCartApi['shippingRuleInfo'];
export type IShippingRule = ICartFromCartApi['shippingRuleInfo']['shippingRule'];
export type IShippingOption = ICartFromCartApi['shippingRuleInfo']['shippingRule']['options'][0];
export type IPickupDetails = ICartFromCartApi['shippingRuleInfo']['shippingRule']['options'][0]['pickupInfo'];
export type IDeliveryTimeSlot = ICartFromCartApi['shippingRuleInfo']['shippingRule']['options'][0]['deliveryTimeSlot'];
export type IAppliedCoupon = ICartFromCartApi['appliedCoupon'];
export type IViolation = ICartFromCartApi['violations'][0];
export type IViolationSeverity = ICartFromCartApi['violations'][0]['severity'];
export type IViolationTarget = ICartFromCartApi['violations'][0]['target'];
export type IViolationOtherTarget = ICartFromCartApi['violations'][0]['target']['other'];
export type IViolationNameInOther = ICartFromCartApi['violations'][0]['target']['other']['name'];
export type IViolationNameInLineItem = ICartFromCartApi['violations'][0]['target']['lineItem']['name'];
export type IViolationLineItemTarget = ICartFromCartApi['violations'][0]['target']['lineItem'];
export type IAdditionalFee = ICartFromCartApi['additionalFees'][0];
export type IDestination = ICartFromCartApi['destination'];
export type IMinimumOrderAmount = ICartFromCartApi['minimumOrderAmount'];
export type IDiscountRule = ICartFromCartApi['items'][0]['discountRules'][0];

export type ICartItemGql = ICartFromCartApi['items'][0];
export type IImageGql = ICartFromCartApi['items'][0]['product']['media'][0];
export type IRenderingConfig = ICartFromCartApi['items'][0]['renderingConfig'];
export type IOptionsSelectionsValue = ICartFromCartApi['items'][0]['optionsSelectionsValues'][0];

export type VeloInputs = {
  shouldShowSecureCheckout?: boolean;
};

export type SettingsOverrides = VeloInputs;

export type ExperimentProps = {
  shouldPresentTooltipWithoutNumber: boolean;
  AddSlotToCartPage: boolean;
  sideCartElementsVisibilityInCss: boolean;
  cartFromSDKWhenShowShippingOrShowTax: boolean;
  useCurrentCartFromSdk: boolean;
  mapCartSummaryColors: boolean;
  checkoutButtonLinkedToSecureCheckout: boolean;
  isGiftCardMixedCartEnabled: boolean;
  hideDepositWhenZero: boolean;
  useProductThumbnailWithWowImage: boolean;
  useSkeletonLineFromWUT: boolean;
  FixCustomLineItemImage: boolean;
};

export type CartStoreProps = {
  shouldRenderEmptyState?: boolean;
  loadingItems: number[];
  cartModel: CartModel;
  estimatedTotals: EstimatedTotalsModel;
  itemsCount?: number;
  isNonShippableCart?: boolean;
  manifest: ICartProductsManifest;
  applyCoupon: (code: string) => Promise<void>;
  removeCoupon: () => Promise<void>;
  couponError?: CouponError;
  isFreeCart?: boolean;
  sendToggleCouponBi: () => void;
  removeItemFromCart: (lineItem: LineItemModel) => Promise<void>;
  sendAddNoteBi: () => void;
  sendMinimumOrderMessageShownBi: (buttonEnabled: boolean) => void;
  sendEstimateTotalsErrorClickOnTryAgainBi: () => void;
  updateBuyerNote: (note: string) => Promise<void>;
  updateItemQuantity: (cartItemId: number, quantity: number) => Promise<void>;
  screenReaderMessage?: string;
  isCartValid: boolean;
  shouldShowCoupon: boolean;
  shouldShowBuyerNote: boolean;
  shouldShowMinimumOrderAmount: boolean;
  shouldShowTopCheckoutButtonInMobile: boolean;
  shouldDisplayViolations: boolean;
  shouldShowSecureCheckout?: boolean;
  isPartiallyPaid: boolean;
  hasErrorViolations: boolean;
  topThreeViolations?: ReturnType<typeof calcTopThreeViolations>;
  shouldShowError: boolean;
  errorOnEstimateTotals: boolean;
  clearError: () => Promise<void>;
  lineItemsLoaded: () => void;
  pageLoaded: () => void;
  isSummaryUpdating: boolean;
  isSummaryLoading: boolean;
  shouldEstimateTotals: boolean;
  fetchEstimateTotals: () => Promise<void>;
} & ExperimentProps;

export enum CartLayout {
  CART = 'cart',
  SIDE_CART = 'sideCart',
}

export type ApplicationError = {details?: {applicationError?: {code?: string}}};
